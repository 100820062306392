//@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./assets/scss/app-variables.scss";


// used for aligning the text along with material icons
.valign-center {
	display: inline-flex;
	vertical-align: middle;
	align-items: center;
}
.valign-center span {
	margin-left: 0.25rem;
}

// **************** Button design *******************************
.btn-icon {
	background-color: transparent;
	border-color: transparent;
	box-shadow: none;
	padding-left: 2px;
	padding-right: 2px;
  }

  .btn-border-none {
	// background-color: transparent;
	// border-color: transparent;
	outline: none;
	box-shadow:none !important;
  }

  // Remove blue border from button on click/focus
// button:focus {
// 	outline: none;
// 	box-shadow:none !important;
// }

/************************* input styles ****************************/
.input-grey {
	border: 0;
	border-radius: 7px;
	background-color: #F8F8F8;
}

// .input-white {
// 	border: 0;
// 	border-radius: 7px;
// 	background-color: #FFFFFF;
// 	box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.15);
// }

// .input-white-label {
// 	color: #979797;
// 	font-family: $app-font-family-1;
// 	font-size: 16px;
//   }

// // align text to right inside input box
// .input-white-text {
// 	text-align: right; // align the cursor and text to right
// 	color: #000000;
// 	font-family: $app-font-family-1;
// 	font-size: 16px;
// }

.input-white-disabled {
	background-color: #E5E5E5;
}

.input-invalid {
	//border: 2px solid rgba(177,94,94,0.41);
	border: 2px solid red;
	border-radius: 7px;
	background-color: #FFFFFF;
}

.input-hint {
	color: #895353;
	font-family: $app-font-family-1;
	font-size: 15px;
	padding-left: 5px;
}

.input-required{
    color: #f44336;
    font-weight: bold;
    margin-left: 3px;
    font-size: 1rem;
}


/************************** Container design ***************************/
.cnt-box-warn {
    background-color: #FFEFEF;
    border-radius: 9px;
    padding: 1rem;
}

.cnt-header-txt {
    color: #000000;
    //font-family: $app-font-family-2;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
  }

.cnt-txt {
    color: #000000;
    //font-family: $app-font-family-1;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
}

.cnt-txt-xl {
  font-size: 22px !important;
}

.cnt-txt-lg {
  font-size: 18px !important;
}

.cnt-txt-sm {
  font-size: 14px;
}

.cnt-txt-xs {
  font-size: 12px;
}

.txt-bold {
  font-weight: bold;
}

.txt-opac {
  opacity: 0.65;
}

.txt-edit {
  color: #EA7286;
}

.txt-warn {
  color: #895353;
}

.txt-white {
	color: #FFFFFF !important;
  }

.txt-primary {
  color: $app-color-primary !important;
  }

.cnt-top-bar {
  height: 55px;
  background: linear-gradient(0deg, rgba(241,241,241,0.39) 0%, rgba(206,194,202,0.19) 100%);
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
}

/******************************** Link styling *********************/
.cnt-link {
	color: $app-color-primary;
  	text-decoration: none;
}

.cnt-link-hover:hover {
	color: $app-color-primary-dark;
  	text-decoration: none;
}

/********************************* Misc ****************************/
.cursor-hand {
	cursor: pointer;
}

.show-line {
	white-space: pre-wrap;
  }

// .body-background {
//     background-color: $app-body-background;
//  }

 .full-page-height {
    min-height: 100vh;
}

.flex-even {
    flex: 1;
  }

  html {
    scroll-behavior: smooth;
  }

  .bg-transparent {
    background-color: transparent !important;
  }


/*************** Width, margin utilities **************************/
.w-20 {
	width: 20%;
}

.w-30 {
	width: 30% !important;
}
.w-33 {
	width: 33% !important;
}
.w-35 {
	width: 35% !important;
}
  
.w-40 {
	width: 40%;
}

.w-45 {
	width: 45%;
}

.w-60 {
	width: 60%;
}

.w-65 {
	width: 65% !important;
}

.w-67 {
	width: 67% !important;
}

.w-70 {
	width: 70%;
}

.w-80 {
	width: 80%;
}

.w-90 {
	width: 90%;
}

.w-100 {
	width: 100%;
}


/** Material icon styles ****************************/
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.material-icons.md-edit { color: #EA7286; }
.material-icons.md-primary { color: $app-color-primary; }
.material-icons.md-primary-dark { color: $app-color-primary-dark; }
.material-icons.md-checked { color: #2FC8CF; }
.material-icons.md-secondary { color: #2FC8CF; }



.material-icons.md-18 { font-size: 18px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/************** Common pages styles *****************************/
.section-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.section-title {
    font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    line-height: 38px;
    color: #000000;
  }

  .section-txt {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }

  // commonn bottom banner
  .bottom-banner {
    padding: 80px 0 80px;
  }

  /* .bottom-banner-bg {
    background-image: url(assets/images/about/about-us-bg.jpg);
    background-color: rgba(0,0,0,0.5);
    background-size: cover;
    background-position: center;
    background-blend-mode: overlay; 
  } */

  .bottom-banner-txt1 {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    color: #FFFFFF;
    letter-spacing: 1px;
  }
  .bottom-banner-txt2 {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-size: 22px;
    font-weight: 400;
    color: #FFFFFF;
    letter-spacing: 0.5px;
  }

  .bottom-banner-btn {
    background-color: #ff3115;
    border: 2px solid #ff3115;
    display: inline-block;
    margin-top: 25px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 20px;
    padding-right: 30px;
    font-size: 26px;
    color: #FFFFFF;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;
  }

  .bottom-banner-btn:hover {
    background-color: #e41f05;
    border-color: #e41f05;
    color: #FFFFFF !important;
  }

  .back-btn {
    cursor: pointer;
  }

  .form-control:disabled {
    cursor: not-allowed;
  }

  
/* Scroll for Table*/

@mixin column-width($order, $width) {
  td:nth-child(#{$order}),
  th:nth-child(#{$order}) {
    @if $width == 0 {
      display: none;
    } @else {
      width: $width;
    }
  }
}

@mixin column-flex-clear($order) {
  td:nth-child(#{$order}),
  th:nth-child(#{$order}) {
    flex: unset;
  }
}

.table-fixed {
  thead > tr, tbody > tr {
    display: flex;
    justify-content: space-around;
    flex: 0 0 auto;

    th, td {
      //flex: 1;
    }
  }
// TO DO
  tbody {
   // overflow-y: scroll;
   // display: flex;
    // flex-direction: column;
  }
}

.table-lock-height > tbody {
  //height: 50vh; // TO DO
}

.table-custom-width {
  // colunm 1
  @include column-flex-clear(1);
  @include column-width(1, 10%);
  
  // colunm 2
  @include column-flex-clear(2);
  @include column-width(2, 15%);
  
  // colunm width sum need to 100%
  // or colunm 3 need auto width
}


.input-control {
  margin-bottom: 1rem;
}

.form-control {
  background-color: #e9ecef;
  border-color: #e9ecef;
  margin-left: 0rem;
  padding: 0rem;
}

.btn {
  width: 8rem;
}

// Scrollbar width

.app-table {
  ::-webkit-scrollbar {
     //width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      //border-radius: 5px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
     // background: grey; 
      //border-radius: 10px;
    }
    
  }

  * {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
}