// variables

// colors
//$app-color-primary: #EA7286;
$app-color-primary: #f44336; //.w3-theme {color:#fff !important; background-color:#f44336 !important}
$app-color-primary-light: #fef4f3; //.w3-theme-light {color:#000 !important; background-color:#fef4f3 !important}
$app-color-primary-dark: #8d1007; //.w3-theme-dark {color:#fff !important; background-color:#8d1007 !important}
$app-color-secondary: #a31131f6;
//$app-color-background: #FAF9F9;
$app-color-background: #FFFFFF;
$app-body-background: #F9F9F9;
//$app-color-header: rgb(234, 234, 234);
// $app-color-third: rgb(0, 162, 232);
// $app-color-fourth: #ffed9b;
$app-color-third: #00A2E8;
$app-color-fourth: #ffed9b;


// font 
$app-font-family-1: 'Lato', sans-serif;
$app-font-family-2: 'Muli', sans-serif;

// border
$app-border-lg: 4px solid $app-color-primary;
$app-border-lg-secondary: 4px solid $app-color-secondary;
$app-border: 3px solid $app-color-primary;
$app-border-secondary: 3px solid $app-color-secondary;
$app-border-radius-sm: 7px;
$app-border-radius: 9px;
$app-border-radius-lg: 11px;

// shadow
$app-box-shadow-lg: 0 2px 7px 0 rgba(0,0,0,0.15);
$app-box-shadow:    0 2px 4px 0 rgba(39,39,39,0.2);

$app-margin-top: 54px;

.w3-theme-l5 {color:#000 !important; background-color:#fef4f3 !important}
.w3-theme-l4 {color:#000 !important; background-color:#fdd9d6 !important}
.w3-theme-l3 {color:#000 !important; background-color:#fbb3ae !important}
.w3-theme-l2 {color:#000 !important; background-color:#f98d85 !important}
.w3-theme-l1 {color:#fff !important; background-color:#f6665c !important}
.w3-theme-d1 {color:#fff !important; background-color:#f32617 !important}
.w3-theme-d2 {color:#fff !important; background-color:#e11a0c !important}
.w3-theme-d3 {color:#fff !important; background-color:#c5170a !important}
.w3-theme-d4 {color:#fff !important; background-color:#a91409 !important}
.w3-theme-d5 {color:#fff !important; background-color:#8d1007 !important}

.w3-theme-light {color:#000 !important; background-color:#fef4f3 !important}
.w3-theme-dark {color:#fff !important; background-color:#8d1007 !important}
.w3-theme-action {color:#fff !important; background-color:#8d1007 !important}

.w3-theme {color:#fff !important; background-color:#f44336 !important}
.w3-text-theme {color:#f44336 !important}
.w3-border-theme {border-color:#f44336 !important}

.w3-hover-theme:hover {color:#fff !important; background-color:#f44336 !important}
.w3-hover-text-theme:hover {color:#f44336 !important}
.w3-hover-border-theme:hover {border-color:#f44336 !important}
